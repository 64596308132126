import $ from "jquery";
import "slick-carousel";

const heroCarousel = () => {
  const heroCarousels = document.querySelectorAll(".js-hero-carousel");

  heroCarousels.forEach((carousel) => {
    $(carousel).slick({
      autoplay: false,
      autoplaySpeed: 5000,
      arrows: false,
      dots: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnDotsHover: false,
    });
  });
};

export default heroCarousel;
